@charset "UTF-8";
.o-grid {
  display: flex;
  margin-left: -16px; }
  .o-grid__col {
    flex: 1;
    padding-left: 16px; }
  .o-grid--full {
    flex: 1; }
  .o-grid--s {
    margin-left: -8px; }
    .o-grid--s .o-grid__col {
      padding-left: 8px; }
  .o-grid--col {
    flex-direction: column; }
    @media screen and (min-width: 1024px) {
      .o-grid--col\@desktop {
        flex-direction: column; } }
    @media screen and (max-width: 1023px) {
      .o-grid--col\@tablet {
        flex-direction: column; } }
    @media screen and (max-width: 768px) {
      .o-grid--col\@medium {
        flex-direction: column; } }
    @media screen and (max-width: 480px) {
      .o-grid--col\@small {
        flex-direction: column; } }

.o-grid__col--1 {
  flex: 0 0 8.33333%; }

.o-grid__col--2 {
  flex: 0 0 16.66667%; }

.o-grid__col--3 {
  flex: 0 0 25%; }

.o-grid__col--4 {
  flex: 0 0 33.33333%; }

.o-grid__col--5 {
  flex: 0 0 41.66667%; }

.o-grid__col--6 {
  flex: 0 0 50%; }

.o-grid__col--7 {
  flex: 0 0 58.33333%; }

.o-grid__col--8 {
  flex: 0 0 66.66667%; }

.o-grid__col--9 {
  flex: 0 0 75%; }

.o-grid__col--10 {
  flex: 0 0 83.33333%; }

.o-grid__col--11 {
  flex: 0 0 91.66667%; }

.o-grid__col--12 {
  flex: 0 0 100%; }

@media screen and (min-width: 1024px) {
  .o-grid__col--1\@desktop {
    flex: 0 0 8.33333%; }
  .o-grid__col--2\@desktop {
    flex: 0 0 16.66667%; }
  .o-grid__col--3\@desktop {
    flex: 0 0 25%; }
  .o-grid__col--4\@desktop {
    flex: 0 0 33.33333%; }
  .o-grid__col--5\@desktop {
    flex: 0 0 41.66667%; }
  .o-grid__col--6\@desktop {
    flex: 0 0 50%; }
  .o-grid__col--7\@desktop {
    flex: 0 0 58.33333%; }
  .o-grid__col--8\@desktop {
    flex: 0 0 66.66667%; }
  .o-grid__col--9\@desktop {
    flex: 0 0 75%; }
  .o-grid__col--10\@desktop {
    flex: 0 0 83.33333%; }
  .o-grid__col--11\@desktop {
    flex: 0 0 91.66667%; }
  .o-grid__col--12\@desktop {
    flex: 0 0 100%; } }

@media screen and (max-width: 1023px) {
  .o-grid__col--1\@tablet {
    flex: 0 0 8.33333%; }
  .o-grid__col--2\@tablet {
    flex: 0 0 16.66667%; }
  .o-grid__col--3\@tablet {
    flex: 0 0 25%; }
  .o-grid__col--4\@tablet {
    flex: 0 0 33.33333%; }
  .o-grid__col--5\@tablet {
    flex: 0 0 41.66667%; }
  .o-grid__col--6\@tablet {
    flex: 0 0 50%; }
  .o-grid__col--7\@tablet {
    flex: 0 0 58.33333%; }
  .o-grid__col--8\@tablet {
    flex: 0 0 66.66667%; }
  .o-grid__col--9\@tablet {
    flex: 0 0 75%; }
  .o-grid__col--10\@tablet {
    flex: 0 0 83.33333%; }
  .o-grid__col--11\@tablet {
    flex: 0 0 91.66667%; }
  .o-grid__col--12\@tablet {
    flex: 0 0 100%; } }

@media screen and (max-width: 768px) {
  .o-grid__col--1\@medium {
    flex: 0 0 8.33333%; }
  .o-grid__col--2\@medium {
    flex: 0 0 16.66667%; }
  .o-grid__col--3\@medium {
    flex: 0 0 25%; }
  .o-grid__col--4\@medium {
    flex: 0 0 33.33333%; }
  .o-grid__col--5\@medium {
    flex: 0 0 41.66667%; }
  .o-grid__col--6\@medium {
    flex: 0 0 50%; }
  .o-grid__col--7\@medium {
    flex: 0 0 58.33333%; }
  .o-grid__col--8\@medium {
    flex: 0 0 66.66667%; }
  .o-grid__col--9\@medium {
    flex: 0 0 75%; }
  .o-grid__col--10\@medium {
    flex: 0 0 83.33333%; }
  .o-grid__col--11\@medium {
    flex: 0 0 91.66667%; }
  .o-grid__col--12\@medium {
    flex: 0 0 100%; } }

@media screen and (max-width: 480px) {
  .o-grid__col--1\@small {
    flex: 0 0 8.33333%; }
  .o-grid__col--2\@small {
    flex: 0 0 16.66667%; }
  .o-grid__col--3\@small {
    flex: 0 0 25%; }
  .o-grid__col--4\@small {
    flex: 0 0 33.33333%; }
  .o-grid__col--5\@small {
    flex: 0 0 41.66667%; }
  .o-grid__col--6\@small {
    flex: 0 0 50%; }
  .o-grid__col--7\@small {
    flex: 0 0 58.33333%; }
  .o-grid__col--8\@small {
    flex: 0 0 66.66667%; }
  .o-grid__col--9\@small {
    flex: 0 0 75%; }
  .o-grid__col--10\@small {
    flex: 0 0 83.33333%; }
  .o-grid__col--11\@small {
    flex: 0 0 91.66667%; }
  .o-grid__col--12\@small {
    flex: 0 0 100%; } }

.o-input-group {
  position: relative; }
  .o-input-group__feedback {
    position: absolute;
    bottom: calc(100% + 12px);
    right: 0; }
  .o-input-group__icon {
    position: absolute;
    z-index: 1;
    right: 8px;
    top: 50%;
    transform: translateY(-50%); }
    .o-input-group__icon > img {
      display: block; }
    .o-input-group__icon + input {
      padding-right: 36px; }

.o-media {
  display: table; }
  .o-media__img, .o-media__body {
    display: table-cell;
    vertical-align: top; }
  .o-media__img {
    padding-right: 1rem; }
    .o-media__img img {
      display: block;
      max-width: none; }
  .o-media__body {
    width: 100%; }
  .o-media--wrap .o-media__body {
    width: auto; }
  .o-media--middle .o-media__img, .o-media--middle .o-media__body {
    vertical-align: middle; }
  .o-media--bottom .o-media__img, .o-media--bottom .o-media__body {
    vertical-align: bottom; }
  .o-media--baseline .o-media__img, .o-media--baseline .o-media__body {
    vertical-align: baseline; }
  .o-media--rev .o-media__img {
    padding-right: 0;
    padding-left: 1rem; }
  .o-media--s .o-media__img {
    padding-right: 0.5rem; }

.o-section {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px; }
  .o-section:after {
    content: '' !important;
    clear: both !important;
    display: block !important; }
  .o-section--xl {
    padding-top: 28px;
    padding-bottom: 28px; }
  .o-section--s {
    padding-top: 12px;
    padding-bottom: 12px; }
  .o-section--xs {
    padding-top: 8px;
    padding-bottom: 8px; }
  .o-section--flush {
    padding-right: 0;
    padding-left: 0; }
  .o-section--full {
    height: 100%; }
  .o-section--gainsboro {
    background-color: #e6eaec; }
  .o-section--lightgrey {
    background-color: #ccd4dd; }
  .o-section--red {
    background-color: #e60017; }
  .o-section--green {
    background-color: #219709; }
  .o-section--border {
    box-shadow: inset 0px 0px 0px 1px rgba(88, 103, 117, 0.1); }

.c-arrow, .c-arrow-down, .c-arrow-right, .c-arrow-top {
  position: relative;
  display: inline-block;
  border-style: solid;
  vertical-align: middle;
  border-color: transparent; }
  .c-arrow-down {
    margin-left: 5px;
    border-width: 5px;
    border-top-color: #586775;
    border-bottom: 0; }
    .c-arrow-down--lightslategrey {
      border-top-color: #7e93a7; }
  .c-arrow-right {
    margin-left: 5px;
    border-width: 5px;
    border-left-color: #586775;
    border-right: 0; }
  .c-arrow-top {
    margin-left: 5px;
    border-width: 5px;
    border-bottom-color: #586775;
    border-top: 0; }

.c-bubble {
  position: relative;
  background-color: #007eff;
  padding: 8px 12px;
  border-radius: 4px;
  color: white; }
  .c-bubble:before {
    content: '';
    position: absolute;
    bottom: -5px;
    right: 16px;
    display: block;
    width: 12px;
    height: 12px;
    background-color: #007eff;
    transform: rotate(45deg); }
  .c-bubble--red {
    background-color: #e60017; }
    .c-bubble--red:before {
      background-color: #e60017; }
  .c-bubble a {
    color: inherit;
    text-decoration: underline; }
    .c-bubble a:hover {
      text-decoration: none; }

.c-button {
  color: #ffffff;
  cursor: pointer;
  border: 1px solid #219709;
  background-color: #23be04;
  border-radius: 4px;
  display: inline-block;
  text-decoration: none;
  line-height: 1;
  text-align: center;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  padding: 11px 27px; }
  .c-button:hover {
    background-color: #25c804; }
  .c-button:active {
    background-color: #219709; }
  .c-button:focus {
    outline: none; }
  .c-button--large {
    font-size: 22px;
    padding: 16px 96px; }
  .c-button--small {
    font-size: 16px;
    padding: 4px 12px; }
  .c-button--block {
    padding-left: 16px;
    padding-right: 16px;
    display: block;
    width: 100%; }
  .c-button--transparent {
    border: solid 1px #b2bdc8;
    background: transparent;
    color: #586775; }
    .c-button--transparent:hover {
      background-color: #e6eaec; }
    .c-button--transparent:active {
      background-color: #ccd4dd; }
  .c-button--white {
    border: solid 1px #b2bdc8;
    background-color: #ffffff;
    color: #586775; }
    .c-button--white:hover {
      background-color: #e6eaec; }
    .c-button--white:active {
      background-color: #ccd4dd; }
  .c-button--silver {
    border: solid 1px #7e93a7;
    background-color: #b2bdc8;
    color: #ffffff; }
    .c-button--silver:hover {
      background-color: #7e93a7; }
    .c-button--silver:active {
      background-color: #586775; }
  .c-button--full-height {
    height: 100%; }
  .c-button--red {
    background-color: #e60017;
    border: 1px solid #CC0A20;
    color: #ffffff; }
    .c-button--red:hover {
      background-color: #f00018; }
    .c-button--red:active {
      background-color: #CC0A20; }
  .c-button--blue {
    background-color: #007eff;
    border: 1px solid #1166C5;
    color: #ffffff; }
    .c-button--blue:hover {
      background-color: #1488ff; }
    .c-button--blue:active {
      background-color: #1166C5; }
  .c-button--ghost {
    background-color: white;
    border: 1px solid #02A2F2;
    color: #02A2F2; }
    .c-button--ghost:hover {
      background-color: #f4f6f7; }
    .c-button--ghost:active {
      background-color: #e6eaec; }
  .c-button--orange {
    background-color: #FF8F00;
    border: 1px solid #FF6F00;
    color: #ffffff; }
    .c-button--orange:hover {
      background-color: #FCAF28; }
    .c-button--orange:active {
      background-color: #FF6F00; }
  .c-button--loading {
    position: relative;
    color: transparent !important; }
    .c-button--loading:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 20px;
      height: 20px;
      padding-right: 2px;
      padding-top: 2px;
      border-top: solid 2px #ffffff;
      border-left: solid 2px #ffffff;
      border-radius: 50%;
      opacity: 1;
      transition: opacity 0.2s;
      animation: spin 2s linear infinite; }
  .c-button--google {
    background-color: #fff;
    border: solid 1px #e6eaec;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.1);
    padding: 8px 12px; }
    .c-button--google:hover, .c-button--google:active {
      background-color: #f6f6f6; }
  .c-button--facebook {
    background-color: #3C5A99;
    border: 1px solid #29487d;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.1);
    padding: 8px 12px; }
    .c-button--facebook:hover, .c-button--facebook:active {
      background-color: #324b80; }
  .c-button[disabled], .c-button--disabled {
    background-color: #ccd4dd;
    border-color: #b2bdc8;
    color: #7e93a7;
    cursor: not-allowed; }
    .c-button[disabled]:hover, .c-button--disabled:hover {
      background-color: #ccd4dd; }
    .c-button[disabled]:before, .c-button--disabled:before {
      border-color: #7e93a7; }

@keyframes spin {
  100% {
    transform: rotate(360deg); } }

.c-captcha__wrapper {
  margin: 0 auto;
  width: 304px; }

.c-check {
  display: inline-block;
  position: relative; }
  .c-check input {
    position: absolute;
    top: -9999px;
    left: -9999px; }
  .c-check__label {
    display: block;
    position: relative;
    padding-left: 26px;
    white-space: nowrap; }
  .c-check__box {
    height: 18px;
    width: 18px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAkCAYAAABv2tHkAAAB5UlEQVRIie3UzWsTQRjH8e+znexLdlq7u0nT1EtBTPHcgxf9O7yKd/8YD56aHvVi9KDgPYrSnkJFhAoVKmhofCkkxIRkd8dDQ2BJgom5CPY5zsx++M3sMyPVvb088AC4A6wyf3WAJ8B9lcvlHlYqlbulUgml1NxCHMerZ63WvY/Hx5YcHhwMyuVyboEEmWo2m0P5dHJiFkkwJRHWMgCAUgprKWFUl8glMhciIksBIoLluu5wGcRx3aG1HgSPfK1ZNJGI4GtNEASPMcbkjTH7xpiOWaw6o+/yar9aBUgBs+BOxuv/55dNa83a2lpmTCnF3ILneawHASJCnCT86nbHc3O1vW3bhFE07iVRdmZ+Asn7Pp7nZeJGhSKWdbH07LzD87fvZyOu6xKEIWEUYds2lmURFYootQJAu9vnxZt33N7ZzCCZM3F9jSUCIkSFIkkSY9sXf78/iKnVG9za2cQeoVOTPHt9RLvbH21jBcdxAEjSlFq9we52hO9MtlQGuXltg1q9QX8Qj8dSY3j66ojrG5pQuxPABOI7OXa3I2r1BkmaAvDy8AMlrdgK/KkAgHw+PZ24eF/Pu3xpDyhc0fS7HW5cDWcCMONl2wp8Akf4/uPnHwERQb61WoNer/fXF9D1vH/oZfsNT64VdzGQHJIAAAAASUVORK5CYII=") no-repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    left: 0; }
  input:checked + .c-check__label > .c-check__box {
    background-position: 0 -18px; }

.c-drop {
  position: relative;
  display: inline-block;
  font-weight: lighter; }
  .c-drop__down {
    text-align: left;
    position: absolute;
    z-index: 90;
    top: -6px;
    left: -16px;
    min-width: 210px;
    padding: 44px 16px 16px;
    display: none;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0px 1px 3px 0px rgba(126, 147, 167, 0.3); }
  .c-drop__label {
    cursor: pointer;
    position: relative;
    z-index: 91; }
  .c-drop__close {
    width: 12px;
    height: 12px;
    position: absolute;
    right: 8px;
    top: 8px;
    text-decoration: none;
    font-size: 22px; }
    .c-drop__close:before {
      content: '×';
      color: #7e93a7;
      text-align: center;
      display: block;
      width: 12px;
      height: 12px;
      line-height: 12px; }
  .c-drop--active .c-drop__down {
    display: block; }
  .c-drop--active .c-drop__label {
    cursor: default; }

.c-input {
  outline: none;
  border: solid 1px #ccd4dd;
  border-radius: 4px;
  background-color: #f4f6f7;
  width: 100%;
  box-sizing: border-box;
  font-size: 18px;
  transition: border-color 0.2s, background-color 0.2s;
  padding: 8px 16px;
  height: auto !important; }
  .c-input:focus, .c-input--focus {
    border-color: #007eff; }
  .c-input--password[type="text"] {
    font-family: sans-serif !important;
    font-weight: lighter; }
    .c-input--password[type="text"]::placeholder {
      font-family: Kievit, sans-serif !important;
      font-weight: inherit; }
    .c-input--password[type="text"]::-webkit-input-placeholder {
      font-family: Kievit, sans-serif !important;
      font-weight: inherit; }
    .c-input--password[type="text"]::-moz-placeholder {
      font-family: Kievit, sans-serif !important;
      font-weight: inherit; }
  .c-input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #f4f6f7 inset; }
    .c-input:-webkit-autofill:focus {
      box-shadow: 0 0 0px 1000px #f8fbff inset; }
  .c-input::-webkit-input-placeholder {
    color: #b2bdc8; }
  .c-input::-moz-placeholder {
    color: #b2bdc8; }
  .c-input--l {
    font-size: 18px;
    line-height: 22px;
    padding-top: 12px;
    padding-bottom: 12px; }
  .c-input--red:focus {
    border-color: #e60017;
    background-color: #fef8f8; }
  .c-input--focus.c-input--red {
    border-color: #e60017;
    background-color: #fef8f8; }

.c-menu {
  cursor: default;
  list-style: none;
  padding: 16px;
  position: absolute;
  right: 0;
  top: 0; }
  .c-menu > li {
    margin-left: 4px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer; }
    .c-menu > li a {
      outline: none; }
    .c-menu > li.c-menu__settings {
      margin-top: -1px; }
    .c-menu > li img {
      display: block;
      opacity: 0.6; }
    .c-menu > li:hover img, .c-menu > li.c-menu__item--active img {
      opacity: 1; }
    .c-menu > li:first-child {
      margin-left: 0; }

.c-dropdown-menu {
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #e6eaec;
  box-shadow: 0 3px 8px 0px rgba(0, 0, 0, 0.08);
  margin-top: 8px;
  position: absolute;
  right: -8px;
  top: 100%;
  padding-top: 8px;
  padding-bottom: 16px; }
  .c-dropdown-menu:after {
    content: '';
    display: inline-block;
    border-style: solid;
    vertical-align: middle;
    border-color: transparent;
    border-width: 4px;
    border-top: 0;
    position: absolute;
    border-bottom-color: #ffffff;
    right: 8px;
    top: -4px; }
  .c-dropdown-menu:before {
    content: '';
    display: inline-block;
    border-style: solid;
    vertical-align: middle;
    border-color: transparent;
    border-width: 5px;
    border-top: 0;
    position: absolute;
    border-bottom-color: #e6eaec;
    right: 7px;
    top: -5px; }
  .c-dropdown-menu--hidden {
    display: none; }
  .c-dropdown-menu__item {
    cursor: pointer;
    font-size: 16px; }
    .c-dropdown-menu__item:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      overflow: hidden; }
    .c-dropdown-menu__item:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      overflow: hidden; }
    .c-dropdown-menu__item > a {
      color: #7e93a7;
      padding: 8px 36px 8px 16px;
      display: block;
      position: relative; }
    .c-dropdown-menu__item:hover > a {
      color: #586775; }
    .c-dropdown-menu__item:active > a {
      color: #007eff; }
  .c-dropdown-menu--action {
    padding-top: 4px;
    padding-bottom: 8px; }
    .c-dropdown-menu--action .c-dropdown-menu__item {
      font-size: 14px; }
      .c-dropdown-menu--action .c-dropdown-menu__item > a {
        padding: 8px 8px 8px 8px; }

.c-password-strength {
  position: relative;
  font-family: Kievit; }
  .c-password-strength__feedback-list:empty {
    display: none; }
  .c-password-strength__bar-container {
    background-color: #ccd4dd;
    height: 4px;
    border-radius: 2px;
    overflow: hidden; }
  .c-password-strength__bar {
    height: 4px;
    border-radius: 2px;
    transition: .4s; }
  .c-password-strength__rating {
    width: 100%;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16px;
    top: 8px;
    font-weight: 500; }
  .c-password-strength__feedback {
    width: 100%;
    border-radius: 2px;
    background-color: #e6eaec;
    position: absolute; }
    .c-password-strength__feedback-list {
      padding: 4px 8px 4px 24px;
      list-style: disc outside;
      color: #7e93a7; }
      .c-password-strength__feedback-list > li {
        font-size: 16px;
        margin-bottom: 4px; }
        .c-password-strength__feedback-list > li > div {
          font-size: 14px;
          line-height: 16px;
          margin-left: -4px; }

.c-password-strength[data-strength="0"] .c-password-strength__bar, [data-strength="0"] .c-password-strength__bar {
  min-width: 2%;
  width: 0%;
  background-color: #E30B23; }

.c-password-strength[data-strength="0"] .c-password-strength__rating, [data-strength="0"] .c-password-strength__rating {
  color: #E30B23; }

.c-password-strength[data-strength="0"].c-password-strength__bgc, [data-strength="0"].c-password-strength__bgc {
  background-color: #E30B23;
  border-top-color: #E30B23; }

.c-password-strength[data-strength="0"].c-password-strength__color, [data-strength="0"].c-password-strength__color {
  color: #E30B23; }

.c-password-strength[data-strength="1"] .c-password-strength__bar, [data-strength="1"] .c-password-strength__bar {
  min-width: 2%;
  width: 25%;
  background-color: #E30B23; }

.c-password-strength[data-strength="1"] .c-password-strength__rating, [data-strength="1"] .c-password-strength__rating {
  color: #E30B23; }

.c-password-strength[data-strength="1"].c-password-strength__bgc, [data-strength="1"].c-password-strength__bgc {
  background-color: #E30B23;
  border-top-color: #E30B23; }

.c-password-strength[data-strength="1"].c-password-strength__color, [data-strength="1"].c-password-strength__color {
  color: #E30B23; }

.c-password-strength[data-strength="2"] .c-password-strength__bar, [data-strength="2"] .c-password-strength__bar {
  min-width: 2%;
  width: 50%;
  background-color: #EF6C1F; }

.c-password-strength[data-strength="2"] .c-password-strength__rating, [data-strength="2"] .c-password-strength__rating {
  color: #EF6C1F; }

.c-password-strength[data-strength="2"].c-password-strength__bgc, [data-strength="2"].c-password-strength__bgc {
  background-color: #EF6C1F;
  border-top-color: #EF6C1F; }

.c-password-strength[data-strength="2"].c-password-strength__color, [data-strength="2"].c-password-strength__color {
  color: #EF6C1F; }

.c-password-strength[data-strength="3"] .c-password-strength__bar, [data-strength="3"] .c-password-strength__bar {
  min-width: 2%;
  width: 75%;
  background-color: #3AAFB8; }

.c-password-strength[data-strength="3"] .c-password-strength__rating, [data-strength="3"] .c-password-strength__rating {
  color: #3AAFB8; }

.c-password-strength[data-strength="3"].c-password-strength__bgc, [data-strength="3"].c-password-strength__bgc {
  background-color: #3AAFB8;
  border-top-color: #3AAFB8; }

.c-password-strength[data-strength="3"].c-password-strength__color, [data-strength="3"].c-password-strength__color {
  color: #3AAFB8; }

.c-password-strength[data-strength="4"] .c-password-strength__bar, [data-strength="4"] .c-password-strength__bar {
  min-width: 2%;
  width: 100%;
  background-color: #3AB29B; }

.c-password-strength[data-strength="4"] .c-password-strength__rating, [data-strength="4"] .c-password-strength__rating {
  color: #3AB29B; }

.c-password-strength[data-strength="4"].c-password-strength__bgc, [data-strength="4"].c-password-strength__bgc {
  background-color: #3AB29B;
  border-top-color: #3AB29B; }

.c-password-strength[data-strength="4"].c-password-strength__color, [data-strength="4"].c-password-strength__color {
  color: #3AB29B; }

.c-setting {
  position: relative;
  display: block;
  line-height: 20px;
  display: flex; }
  .c-setting__title {
    vertical-align: middle;
    flex-grow: 1; }
    .c-setting__title a {
      text-decoration: none;
      color: #586775; }
  .c-setting input {
    visibility: hidden;
    width: 0;
    height: 0;
    position: absolute;
    margin: 0;
    top: -1px;
    right: -1px; }
  .c-setting__switch {
    width: 40px;
    height: 20px;
    border: solid 1px #ccd4dd;
    border-radius: 10px;
    background-color: white;
    box-shadow: inset 0 1px 2px rgba(204, 212, 221, 0.3);
    position: relative;
    vertical-align: middle;
    flex-shrink: 0;
    cursor: pointer; }
    .c-setting__switch::before {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #ccd4dd;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
      transition: left 0.1s ease-out, background 0.1s ease-out; }
    .c-setting__switch::after {
      content: '';
      position: absolute;
      top: 6px;
      left: 5px;
      width: 8px;
      height: 4px;
      border-bottom: solid 1px #ccd4dd;
      border-left: solid 1px #ccd4dd;
      transform: rotate(-40deg);
      transition: left 0.1s ease-out, border-color 0.1s ease-out; }
  input:checked ~ .c-setting__switch::before {
    background-color: #219709;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.25);
    left: 21px; }
  input:checked ~ .c-setting__switch::after {
    left: 25px;
    border-color: white; }
  .c-setting__arrow {
    margin: auto;
    cursor: pointer;
    border: none;
    outline: none; }
    .c-setting__arrow:before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border-top: solid 2px #ccd4dd;
      border-right: solid 2px #ccd4dd;
      transform: translateY(-50%) rotate(45deg);
      position: absolute;
      right: 5px;
      top: 50%; }

.c-tag-container {
  display: flex;
  flex-flow: row wrap; }
  .c-tag-container .c-tag {
    font-size: 14px;
    background-color: transparent;
    border: 1px solid #ccd4dd;
    border-radius: 16px;
    color: #b2bdc8;
    padding: 4px 12px;
    vertical-align: middle;
    min-width: 82px;
    text-align: center;
    margin-bottom: 8px;
    cursor: pointer;
    outline: none;
    margin-right: 8px;
    justify-content: center; }
    .c-tag-container .c-tag__filter-count {
      font-size: 12px;
      margin-left: 4px; }
    .c-tag-container .c-tag:last-child {
      margin-right: 0px; }
    .c-tag-container .c-tag:hover {
      border-color: #7e93a7;
      background-color: rgba(126, 147, 167, 0.05);
      color: #7e93a7; }
      .c-tag-container .c-tag:hover .c-icon {
        color: #7e93a7;
        fill: #7e93a7; }
    .c-tag-container .c-tag:active, .c-tag-container .c-tag--active, .c-tag-container .c-tag--active:hover {
      border-color: #007eff;
      background-color: rgba(0, 126, 255, 0.05);
      color: #007eff; }
      .c-tag-container .c-tag:active .c-icon, .c-tag-container .c-tag--active .c-icon, .c-tag-container .c-tag--active:hover .c-icon {
        color: #007eff;
        fill: #007eff; }
    .c-tag-container .c-tag--green {
      border-color: #23be04;
      color: #23be04; }
    .c-tag-container .c-tag--disabled {
      pointer-events: none; }
    .c-tag-container .c-tag--left {
      text-align: left; }
    .c-tag-container .c-tag__action:hover {
      color: #007eff; }
    .c-tag-container .c-tag .c-dash-input {
      background-color: inherit;
      font-size: 14px;
      padding: 0; }
  .c-tag-container--pentagon .c-tag {
    position: relative;
    justify-content: start;
    width: 106px;
    height: 68px;
    border-radius: 0%;
    border: 1px solid transparent;
    border-bottom: 0px;
    background-color: transparent;
    color: #b2bdc8;
    display: flex;
    flex-direction: column;
    padding: 4px;
    align-items: center;
    margin-bottom: 36px; }
    .c-tag-container--pentagon .c-tag:before, .c-tag-container--pentagon .c-tag:after {
      position: absolute;
      height: 0;
      content: '';
      top: 100%; }
    .c-tag-container--pentagon .c-tag:before {
      left: -1px;
      right: 0px;
      border-left: 53px solid transparent;
      border-right: 53px solid transparent; }
    .c-tag-container--pentagon .c-tag:after {
      left: 0px;
      right: 0px;
      border-top: 24px solid #f4f6f7;
      border-left: 52px solid transparent;
      border-right: 52px solid transparent; }
    .c-tag-container--pentagon .c-tag:hover {
      border-color: #7e93a7;
      color: #7e93a7;
      background-color: #f4f6f7; }
      .c-tag-container--pentagon .c-tag:hover:before {
        border-top: 25px solid #7e93a7; }
    .c-tag-container--pentagon .c-tag:active, .c-tag-container--pentagon .c-tag--active, .c-tag-container--pentagon .c-tag--active:hover {
      border-color: #007eff;
      color: #007eff;
      background-color: #f4f6f7; }
      .c-tag-container--pentagon .c-tag:active:before, .c-tag-container--pentagon .c-tag--active:before, .c-tag-container--pentagon .c-tag--active:hover:before {
        border-top: 25px solid #007eff; }
    .c-tag-container--pentagon .c-tag--disabled {
      pointer-events: none; }

.c-tabs {
  display: flex; }
  .c-tabs__item {
    color: #7e93a7;
    margin-left: 20px;
    font-size: 16px; }
    .c-tabs__item:hover {
      color: #586775; }
    .c-tabs__item:first-child {
      margin-left: 0; }
    .c-tabs__item--active {
      color: #007eff;
      border-bottom: 2px solid #007eff; }
      .c-tabs__item--active:hover {
        color: #007eff; }

.c-datali {
  position: relative;
  transition: all 400ms;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 62px; }
  .c-datali__item {
    margin-right: 16px; }
    .c-datali__item--shrink {
      flex-shrink: 1;
      flex-grow: 0; }
    .c-datali__item--fixed {
      flex-shrink: 0;
      flex-grow: 0;
      flex-basis: auto; }
    .c-datali__item--domain, .c-datali__item--title {
      flex-basis: 10em;
      flex-shrink: 2; }
    .c-datali__item--modified {
      flex-basis: 8em;
      text-align: center;
      flex-shrink: 2; }
    .c-datali__item--username {
      flex-basis: 13em; }
    .c-datali__item--note {
      flex-basis: 20em;
      flex-grow: 3;
      flex-shrink: 2; }
    .c-datali__item--password-descriptors {
      position: absolute;
      top: 100%;
      font-size: 12px;
      font-weight: 700;
      line-height: 12px;
      text-transform: uppercase;
      white-space: nowrap; }
    .c-upsell .c-datali__item--blur {
      -webkit-filter: blur(7px);
      filter: blur(7px); }
    .c-upsell .c-datali__item--hidden {
      visibility: hidden; }
  .c-datali__hidden-password {
    display: block;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 4px; }
    .c-datali__hidden-password::before {
      content: '••••••••'; }
  .c-datali__spacer {
    flex-basis: 0;
    flex-grow: 3;
    flex-shrink: 0; }
  .c-datali__spacer-fixed {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 0; }
  .c-datali__hidden {
    opacity: 0;
    transition: opacity 400ms; }
  .c-datali__loading {
    position: absolute;
    top: 20px;
    right: 16px; }
  .c-datali:hover {
    background-color: #e6eaec;
    cursor: pointer; }
    .c-datali:hover .c-datali__hidden {
      opacity: 1; }
  .c-datali--noclick:hover {
    cursor: default; }
  .c-datali:last-child {
    margin-bottom: 0px; }
  @media screen and (max-width: 960px) {
    .c-datali__hidden-password::before {
      content: '•••••'; }
    .c-datali__item--collapse {
      display: none; } }

.c-card {
  display: flex;
  flex-direction: column; }
  .c-card__label {
    text-transform: uppercase;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    font-size: 12px; }
  .c-card__tile {
    background-color: #ffffff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    padding: 16px; }
  .c-card__favorite {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 8px 8px;
    cursor: pointer; }
  .c-card--large .c-card__tile {
    padding: 36px; }
  .c-card--nopad .c-card__tile {
    padding: 0; }
  .c-card--placeholder .c-card__tile {
    background-color: transparent;
    height: 62px;
    border: 1px dashed #b2bdc8;
    box-shadow: none;
    border-radius: 4px; }

.c-card-list__item {
  border-bottom: 1px solid #e6eaec;
  padding: 12px 16px; }
  .c-card-list__item:last-child {
    border-bottom: 0px; }

.c-checkbox {
  font-size: 14px; }
  .c-checkbox input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: none; }
    .c-checkbox input:checked:after {
      color: #7e93a7; }
    .c-checkbox input:disabled {
      opacity: 0.3; }
  .c-checkbox__check {
    display: inline-block;
    position: relative;
    vertical-align: top;
    cursor: default; }
    .c-checkbox__check:before {
      content: '';
      width: 14px;
      height: 14px;
      background-color: transparent;
      outline: none;
      border: 1px solid #7e93a7;
      padding: 6px;
      margin: 0;
      margin-right: 8px;
      border-radius: 3px;
      display: inline-block;
      position: relative;
      vertical-align: -2px; }
    .c-checkbox__check:after {
      content: '\2713';
      font-size: 18px;
      position: absolute;
      top: -2px;
      left: 1px;
      color: transparent;
      transition: color 0.2s; }
    .c-checkbox__check--no-margin:before {
      margin: 0; }
  input:checked + .c-checkbox__check:after {
    color: #7e93a7; }
  input:disabled + .c-checkbox__check {
    opacity: 0.3; }
  .c-checkbox--white .c-checkbox__check:before {
    border-color: white; }
  .c-checkbox--white input:checked + .c-checkbox__check:after {
    color: white; }

.c-icon {
  color: #b2bdc8;
  fill: #b2bdc8;
  transition: fill 0.2s, color 0.2s; }
  .c-icon__icon {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    color: inherit;
    fill: inherit; }
  .c-icon:hover {
    color: #7e93a7;
    fill: #7e93a7; }
  .c-icon:active, .c-icon--active {
    color: #007eff;
    fill: #007eff; }
    .c-icon:active:hover, .c-icon--active:hover {
      color: #007eff;
      fill: #007eff; }
    .c-icon:active.c-icon--favorites, .c-icon--active.c-icon--favorites {
      color: #F1D802;
      fill: #F1D802; }
  .c-icon__label {
    font-size: 12px;
    white-space: nowrap;
    color: inherit; }
    .c-icon__label span {
      color: inherit; }
  .c-icon--small .c-icon__icon {
    width: 17px;
    height: 16px; }
  .c-icon--large .c-icon__icon {
    width: 29px;
    height: 28px; }
  .c-icon--disabled {
    opacity: .4;
    pointer-events: none; }
  .c-icon--red {
    color: #e60017;
    fill: #e60017; }
    .c-icon--red:hover {
      color: #e60017;
      fill: #e60017; }
    .c-icon--red:active, .c-icon--red--active {
      color: #e60017;
      fill: #e60017; }
      .c-icon--red:active:hover, .c-icon--red--active:hover {
        color: #e60017;
        fill: #e60017; }
  .c-icon--strengthorange {
    color: #EF6C1F;
    fill: #EF6C1F; }
    .c-icon--strengthorange:hover {
      color: #EF6C1F;
      fill: #EF6C1F; }
    .c-icon--strengthorange:active, .c-icon--strengthorange--active {
      color: #EF6C1F;
      fill: #EF6C1F; }
      .c-icon--strengthorange:active:hover, .c-icon--strengthorange--active:hover {
        color: #EF6C1F;
        fill: #EF6C1F; }
  .c-icon--green {
    color: #219709;
    fill: #219709; }
    .c-icon--green:hover {
      color: #219709;
      fill: #219709; }
    .c-icon--green:active, .c-icon--green--active {
      color: #219709;
      fill: #219709; }
      .c-icon--green:active:hover, .c-icon--green--active:hover {
        color: #219709;
        fill: #219709; }
  .c-icon--white {
    color: #ffffff;
    fill: #ffffff; }
    .c-icon--white:hover {
      color: #ffffff;
      fill: #ffffff; }
    .c-icon--white:active, .c-icon--white--active {
      color: #ffffff;
      fill: #ffffff; }
      .c-icon--white:active:hover, .c-icon--white--active:hover {
        color: #ffffff;
        fill: #ffffff; }
  .c-icon--strengthred {
    color: #E30B23;
    fill: #E30B23; }
    .c-icon--strengthred:hover {
      color: #E30B23;
      fill: #E30B23; }
    .c-icon--strengthred:active, .c-icon--strengthred--active {
      color: #E30B23;
      fill: #E30B23; }
      .c-icon--strengthred:active:hover, .c-icon--strengthred--active:hover {
        color: #E30B23;
        fill: #E30B23; }
  .c-icon--yellow {
    color: #F1D802;
    fill: #F1D802; }
    .c-icon--yellow:hover {
      color: #F1D802;
      fill: #F1D802; }
    .c-icon--yellow:active, .c-icon--yellow--active {
      color: #F1D802;
      fill: #F1D802; }
      .c-icon--yellow:active:hover, .c-icon--yellow--active:hover {
        color: #F1D802;
        fill: #F1D802; }

.c-list {
  position: relative; }
  .c-list__header {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #e6eaec;
    font-size: 14px;
    font-weight: 500;
    color: #586775; }
    .c-list__headercell {
      flex: 1 1 100px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 0;
      padding: 0 8px; }
      .c-list__headercell span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
  .c-list__shadow-top {
    overflow: hidden;
    position: absolute;
    right: 0px;
    left: 0px;
    height: 8px; }
    .c-list__shadow-top:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.15);
      z-index: 1;
      display: block;
      content: ''; }
  .c-list__row {
    display: flex;
    flex-direction: row;
    font-size: 16px; }
    .c-list__row:nth-child(even) {
      background-color: #f4f6f7; }
  .c-list__cell {
    flex: 1 1 100px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 0;
    padding: 0 8px; }
    .c-list__cell span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

.c-scrolling-table {
  width: 100%;
  position: relative;
  overflow: hidden; }
  .c-scrolling-table div {
    outline: none; }
  .c-scrolling-table__body {
    width: 100%; }
  .c-scrolling-table__cell {
    background-color: #ffffff;
    padding: 10px 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .c-scrolling-table__row {
    min-width: 100%; }
    .c-scrolling-table__row--even {
      background-color: #f4f6f7; }
  .c-scrolling-table__fixed-cell {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 12; }
    .c-scrolling-table__fixed-cell .c-scrolling-table__cell {
      border-right: solid 1px #e6eaec;
      border-bottom: solid 1px #e6eaec; }
  .c-scrolling-table__left-column {
    overflow: visible !important;
    position: absolute; }
    .c-scrolling-table__left-column-wrapper {
      position: absolute;
      left: 0;
      z-index: 6; }
    .c-scrolling-table__left-column .c-scrolling-table__cell {
      border-right: solid 1px #e6eaec; }
  .c-scrolling-table__header-row {
    width: 100%;
    overflow: hidden !important;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
    z-index: 9; }
    .c-scrolling-table__header-row .c-scrolling-table__cell {
      border-bottom: solid 1px #e6eaec; }

.c-select {
  position: relative;
  box-sizing: border-box;
  border: 1px solid #e6eaec;
  border-radius: 3px;
  background: transparent;
  overflow: hidden;
  display: inline-block;
  vertical-align: top; }
  .c-select:after {
    position: absolute;
    content: '';
    top: 50%;
    right: 10px;
    margin-top: -2px;
    border: 5px solid transparent;
    border-top-color: #7e93a7;
    pointer-events: none; }
  .c-select > select {
    padding: 5px 28px 5px 8px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    border: none;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: none; }
    .c-select > select:focus {
      outline: none; }
  .c-select--disabled:after {
    display: none; }

.c-wizard-steps {
  display: flex;
  align-items: center;
  padding-bottom: 28px; }
  .c-wizard-steps__step {
    display: flex;
    align-items: center;
    flex-grow: 1;
    color: #007eff; }
    .c-wizard-steps__step:before {
      content: '';
      flex-grow: 1;
      border-bottom: solid 4px;
      order: -1; }
    .c-wizard-steps__step__badge {
      width: 30px;
      height: 30px;
      border-radius: 50px;
      border: solid 2px;
      line-height: 26px;
      text-align: center;
      font-size: 22px;
      position: relative; }
      .c-wizard-steps__step__badge:before {
        content: '\2713';
        position: relative;
        top: -1px; }
    .c-wizard-steps__step__label {
      white-space: nowrap;
      position: absolute;
      display: block;
      right: 0;
      top: calc(100% + 8px);
      font-size: 16px;
      line-height: 20px;
      transform: translate(50%);
      right: 50%; }
    .c-wizard-steps__step:first-child {
      flex-grow: 0; }
      .c-wizard-steps__step:first-child:before {
        display: none; }
    .c-wizard-steps__step--active {
      color: #007eff; }
      .c-wizard-steps__step--active .c-wizard-steps__step__badge:before {
        content: attr(data-step); }
      .c-wizard-steps__step--active ~ .c-wizard-steps__step {
        color: #b2bdc8; }
        .c-wizard-steps__step--active ~ .c-wizard-steps__step:before {
          color: #e6eaec; }
        .c-wizard-steps__step--active ~ .c-wizard-steps__step .c-wizard-steps__step__badge:before {
          content: attr(data-step); }

.c-browser-magic {
  margin-top: -4px; }

.c-info-tooltip {
  position: absolute;
  bottom: 4px; }

.c-banner {
  position: relative;
  display: block;
  transition: opacity 500ms, visibility 0s ease-out 500ms, margin-bottom 500ms ease-in 500ms; }
  .c-banner > div {
    padding: 16px 28px; }
  .c-banner__x-btn {
    position: absolute;
    top: 4px;
    right: 4px;
    padding: 0;
    border: none;
    background: transparent; }
  .c-banner--darkshadow {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25); }
  .c-banner--hidden {
    opacity: 0;
    height: 0;
    visibility: hidden;
    margin-bottom: 0 !important; }

.c-upsell {
  position: relative;
  min-height: 200px; }
  .c-upsell__gift-box {
    position: absolute;
    top: 62px;
    z-index: 20;
    left: calc(50% - 45px); }
  .c-upsell__container {
    background: rgba(244, 246, 247, 0.75);
    bottom: 0;
    left: 0;
    top: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    display: flex;
    justify-content: center; }
    .c-upsell__container > * {
      margin-top: 124px; }
  .c-upsell__msg {
    font-size: 22px;
    color: #EF6C1F; }

.c-unlock-pro {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 48px; }
  .c-unlock-pro > h2 {
    font-size: 18px;
    font-weight: bold; }
  .c-unlock-pro__header {
    font-weight: bold; }
  .c-unlock-pro--auth-pages, .c-unlock-pro--ftu-page-C2 {
    background: #DB0D15;
    color: white; }
  .c-unlock-pro--auth-pages {
    height: 250px;
    width: 300px;
    top: calc(50% - 125px);
    right: 0;
    clip-path: polygon(10% 0, 100% 15%, 100% 100%, 0 85%); }
    .c-unlock-pro--auth-pages > p {
      font-size: 16px; }
  .c-unlock-pro--ftu-page-C2 {
    height: 160px;
    width: 320px;
    top: calc(50% - 80px);
    right: -8px;
    border-radius: 4px; }
    @media screen and (max-width: 1023px) {
      .c-unlock-pro--ftu-page-C2 {
        top: 70%; } }
  .c-unlock-pro--security-status {
    background: white;
    width: 500px;
    height: 160px;
    border-radius: 16px;
    color: #586775;
    align-items: center;
    text-align: center;
    clip-path: polygon(15% 0%, 85% 0%, 100% 100%, 0% 100%); }
    .c-unlock-pro--security-status > h2 {
      margin-bottom: 8px;
      margin-top: 48px; }
    .c-unlock-pro--security-status > p {
      font-size: 18px;
      color: #7e93a7; }

.c-dashboard {
  min-width: 592px;
  max-width: 1102px;
  margin: 0 auto;
  padding-left: 22px;
  padding-right: 22px; }
  .c-dashboard__container {
    width: 100%; }

.c-header {
  padding: 12px;
  background-color: #ffffff;
  z-index: 20; }
  .c-header--shadow {
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.12); }
  .c-header--bottom-line {
    border-bottom: 1px solid #e6eaec; }
  .c-header__user {
    white-space: nowrap; }
  .c-header--transparent-bg {
    background-color: transparent; }

.c-ext-banner {
  margin-top: -36px; }
  .c-ext-banner > div {
    padding: 28px; }

.c-sidebar {
  max-width: 100%;
  transition: all 0.3s;
  background: linear-gradient(#3EAED5 0%, #23be04 100%);
  overflow: hidden; }
  .c-sidebar__toggle {
    color: #FFF;
    top: 0;
    right: 0; }
    .c-sidebar__toggle > a {
      cursor: pointer;
      outline: none; }
      .c-sidebar__toggle > a > img {
        transition: transform 0.3s; }
  .c-sidebar__menu__item {
    color: #FFF;
    margin-bottom: 28px;
    position: relative;
    cursor: pointer; }
    .c-sidebar__menu__item > a {
      color: inherit;
      outline: none; }
    .c-sidebar__menu__item__body {
      transition: opacity 0.3s;
      white-space: nowrap;
      opacity: 1; }
    .c-sidebar__menu__item__img {
      min-width: 50px;
      transition: padding 0.3s linear; }
      .c-sidebar__menu__item__img > img {
        margin: auto;
        display: block; }
    .c-sidebar__menu__item--active > a {
      color: #ffffff; }
    .c-sidebar__menu__item--active:before {
      content: '';
      position: absolute;
      right: -8px;
      top: 0;
      bottom: 0;
      margin: auto;
      display: block;
      width: 12px;
      height: 12px;
      background-color: #f4f6f7;
      transform: rotate(45deg); }
  .c-sidebar--collapsed {
    max-width: 250px;
    min-width: 250px; }
    .c-sidebar--collapsed .c-sidebar__menu__item__body {
      opacity: 0; }
    .c-sidebar--collapsed .c-sidebar__menu__item__img {
      padding-left: 0; }
    .c-sidebar--collapsed .c-sidebar__toggle img {
      transform: rotate(180deg); }

.o-device-box {
  width: 200px; }

.c-security-graph {
  width: 100%;
  height: 100%; }
  .c-security-graph__segment {
    transition: stroke-dashoffset 1.5s ease;
    transform-origin: center;
    fill: transparent; }
  .c-security-graph__ring {
    fill: transparent;
    stroke: #e6eaec; }
  .c-security-graph__text {
    font-size: 0.6em;
    line-height: 1;
    text-anchor: middle; }
    .c-security-graph__text--small {
      font-size: 0.5em; }
    .c-security-graph__text--secure {
      fill: #3AB29B;
      color: #3AB29B; }
    .c-security-graph__text--average {
      fill: #EF6C1F;
      color: #EF6C1F; }
    .c-security-graph__text--critical {
      fill: #E30B23;
      color: #E30B23; }
    .c-security-graph__text--disabled, .c-security-graph__text--loading {
      fill: #7e93a7;
      color: #7e93a7; }

.c-security-status[data-strength="0"], .c-security-status[data-strength="1"], .c-security-status--critical, .c-security-status--reused {
  color: #E30B23; }

.c-security-status[data-strength="2"] {
  color: #EF6C1F; }

.c-security-status[data-strength="3"], .c-security-status[data-strength="4"], .c-security-status--secure, .c-security-status--unique {
  color: #3AB29B;
  font-weight: 500; }

.c-security-status--ignore, .c-security-status--ignore[data-strength] {
  color: #7e93a7; }

.c-change-mp__form {
  width: 40%; }

.c-change-mp__action-btn {
  width: 250px;
  margin: auto;
  height: auto; }

.c-change-mp__reused-list {
  list-style-type: disc;
  margin: 20px; }

.c-importer__or {
  height: 52px;
  width: 52px;
  background-color: #ccd4dd;
  border-radius: 50%;
  line-height: 52px;
  text-align: center; }

.c-importer__strong {
  font-weight: 800;
  color: #586775; }

.c-ftu-c2-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas: "highlights laptop" "mobile advantages";
  grid-gap: 0 10%;
  height: 100%;
  padding-left: 30px;
  margin-left: 1rem; }
  @media screen and (max-width: 1023px) {
    .c-ftu-c2-grid {
      display: flex;
      flex-direction: column; } }
  .c-ftu-c2-grid__quadrant:first-child {
    margin-top: 0; }
  .c-ftu-c2-grid__quadrant--1 {
    grid-area: highlights;
    order: 1; }
    @media screen and (max-width: 1023px) {
      .c-ftu-c2-grid__quadrant--1 {
        margin-right: 28px; } }
  .c-ftu-c2-grid__quadrant--2 {
    grid-area: laptop;
    display: grid;
    justify-items: end;
    order: 2; }
    @media screen and (max-width: 1023px) {
      .c-ftu-c2-grid__quadrant--2 {
        margin-top: 36px; } }
  .c-ftu-c2-grid__quadrant--3 {
    grid-area: mobile;
    display: grid;
    align-items: end;
    order: 3; }
    .c-ftu-c2-grid__quadrant--3 .c-powered-by {
      display: none; }
    @media screen and (max-width: 1023px) {
      .c-ftu-c2-grid__quadrant--3 {
        margin-top: 36px;
        order: 4; }
        .c-ftu-c2-grid__quadrant--3 .c-powered-by {
          display: block;
          margin-right: 48px;
          margin-top: 16px; } }
  .c-ftu-c2-grid__quadrant--4 {
    grid-area: advantages;
    order: 4;
    display: grid; }
    .c-ftu-c2-grid__quadrant--4 ul {
      list-style-type: disc;
      padding-left: 1em;
      margin-right: 48px; }
    .c-ftu-c2-grid__quadrant--4 .c-powered-by {
      display: block;
      align-self: end;
      margin-bottom: 36px;
      margin-right: 64px; }
    @media screen and (max-width: 1023px) {
      .c-ftu-c2-grid__quadrant--4 {
        order: 3; }
        .c-ftu-c2-grid__quadrant--4 .c-powered-by {
          display: none; } }

.c-ftu-page-d {
  background-position: left 144px bottom;
  background-size: auto 70%;
  background-repeat: no-repeat;
  background-image: url(../img/giant_lock_dark_scheme@1x.png);
  background-color: #161F25;
  overflow: auto; }
  .c-ftu-page-d__left-section {
    padding: 54px 48px 48px 64px;
    min-width: 400px;
    width: 400px; }
  .c-ftu-page-d__right-section {
    padding-top: 64px;
    margin: 48px 80px;
    min-width: 400px; }
    .c-ftu-page-d__right-section > div:first-child {
      min-height: 608px; }
  .c-ftu-page-d .c-auth-page__register-header {
    font-weight: 700; }

.c-button--tablebutton {
  display: block;
  width: "90%";
  margin: "auto"; }

.c-icon--my {
  flex: auto; }

.c-why-register {
  margin: auto;
  margin-bottom: 30px; }
  .c-why-register__headerRow th {
    max-width: 300px;
    min-width: 200px;
    padding-top: 48px;
    padding-bottom: 28px;
    text-align: center;
    font-size: 18px; }
  .c-why-register__cell {
    border-bottom: 1px solid #ccd4dd;
    display: block; }
  .c-why-register__row td {
    vertical-align: bottom;
    text-align: right; }
  .c-why-register__row td:first-child > span {
    display: inline-block; }
  .c-why-register__row td:not(:first-child) > span {
    text-align: center; }
  .c-why-register__row td > button {
    height: 600px; }
  .c-why-register__row td:first-child > span > span {
    margin-right: 4px; }
  .c-why-register__row-center-text {
    text-align: center;
    padding-top: 36px; }
  .c-why-register__label {
    font-size: 16px; }

html, body, #root {
  height: 100%; }

.o-section--b {
  border: solid 1px; }

.o-table {
  display: table; }
  .o-table__tr {
    display: table-row; }
  .o-table__td {
    display: table-cell;
    padding: 0 10px; }
  .o-table--m .o-table__tr, .o-table--m .o-table__td {
    vertical-align: middle; }

.o-centered {
  min-width: 592px;
  max-width: 1102px;
  margin: 0 auto;
  padding-left: 22px;
  padding-right: 22px; }

.c-scrollwrapper {
  overflow: hidden;
  position: relative; }
  .c-scrollwrapper__bottom-shadow, .c-scrollwrapper__top-shadow {
    overflow: hidden;
    position: absolute;
    right: 16px;
    left: 4px;
    height: 8px;
    top: 0; }
    .c-scrollwrapper__bottom-shadow:before, .c-scrollwrapper__top-shadow:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.15);
      z-index: 1;
      display: block;
      content: ''; }
  .c-scrollwrapper__bottom-shadow {
    top: auto;
    bottom: 0; }
    .c-scrollwrapper__bottom-shadow:before {
      position: absolute;
      top: auto;
      bottom: 0; }
  .c-scrollwrapper__content--no-clicks {
    pointer-events: none; }
  .c-scrollwrapper__content--defaultpadding .scrollarea-content {
    padding: 4px 4px 4px; }

.c-scrollcontent {
  min-height: 0; }
  .c-scrollcontent--bg-height {
    min-height: 350px; }

.u-d-n {
  display: none; }

.u-width-full {
  width: 100%; }

.u-maxwidth-full {
  max-width: 100%; }

.u-fx-1, .o-grid--full {
  flex: 1 0 auto !important; }

.u-color-black {
  color: #000000; }

.u-bg-black {
  background-color: #000000; }

.u-color-slategrey {
  color: #586775; }

.u-bg-slategrey {
  background-color: #586775; }

.u-color-lightslategrey {
  color: #7e93a7; }

.u-bg-lightslategrey {
  background-color: #7e93a7; }

.u-color-silver {
  color: #b2bdc8; }

.u-bg-silver {
  background-color: #b2bdc8; }

.u-color-lightgrey {
  color: #ccd4dd; }

.u-bg-lightgrey {
  background-color: #ccd4dd; }

.u-color-gainsboro {
  color: #e6eaec; }

.u-bg-gainsboro {
  background-color: #e6eaec; }

.u-color-smokegrey {
  color: #f4f6f7; }

.u-bg-smokegrey {
  background-color: #f4f6f7; }

.u-color-royalblue {
  color: #007eff; }

.u-bg-royalblue {
  background-color: #007eff; }

.u-color-darkroyalblue {
  color: #1166C5; }

.u-bg-darkroyalblue {
  background-color: #1166C5; }

.u-color-lightskyblue {
  color: #93c2fa; }

.u-bg-lightskyblue {
  background-color: #93c2fa; }

.u-color-red {
  color: #e60017; }

.u-bg-red {
  background-color: #e60017; }

.u-color-darkorange {
  color: #f26c00; }

.u-bg-darkorange {
  background-color: #f26c00; }

.u-color-orange {
  color: #EF6C1F; }

.u-bg-orange {
  background-color: #EF6C1F; }

.u-color-green {
  color: #219709; }

.u-bg-green {
  background-color: #219709; }

.u-color-limegreen {
  color: #23be04; }

.u-bg-limegreen {
  background-color: #23be04; }

.u-color-white {
  color: #ffffff; }

.u-bg-white {
  background-color: #ffffff; }

.u-color-transparent {
  color: rgba(0, 0, 0, 0); }

.u-bg-transparent {
  background-color: rgba(0, 0, 0, 0); }

.u-flex {
  display: flex; }
  .u-flex--col {
    flex-direction: column; }
  .u-flex--c {
    justify-content: center; }
  .u-flex--sb {
    justify-content: space-between; }
  .u-flex--g1 {
    flex-grow: 1; }

.u-fx {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex; }
  .u-fx-1 {
    flex: 1; }
  .u-fx-d-r {
    flex-direction: row; }
  .u-fx-d-rr {
    flex-direction: row-reverse; }
  .u-fx-d-c {
    flex-direction: column; }
  .u-fx-d-cr {
    flex-direction: column-reverse; }
  .u-fx-jc-c {
    justify-content: center; }
  .u-fx-jc-e {
    justify-content: flex-end; }
  .u-fx-jc-s {
    justify-content: flex-start; }
  .u-fx-jc-sa {
    justify-content: space-around; }
  .u-fx-jc-sb {
    justify-content: space-between; }
  .u-fx-ai-s {
    align-items: flex-start; }
  .u-fx-ai-e {
    align-items: flex-end; }
  .u-fx-ai-c {
    align-items: center; }
  .u-fx-ai-b {
    align-items: baseline; }
  .u-fx-ai-s {
    align-items: stretch; }
  .u-fx-g-0 {
    flex-grow: 0; }
  .u-fx-g-1 {
    flex-grow: 1; }
  .u-fx-g-1.5 {
    flex-grow: 1.5; }
  .u-fx-g-2 {
    flex-grow: 2; }
  .u-fx-s-0 {
    flex-shrink: 0; }
  .u-fx-s-1 {
    flex-shrink: 1; }
  .u-fx-b-0 {
    flex-basis: 0; }
  .u-fx-as-fe {
    align-self: flex-end; }
  .u-fx-w {
    flex-wrap: wrap; }

.u-m-a {
  margin: auto; }

.u-m-xxs {
  margin: 4px; }

.u-m-xs {
  margin: 8px; }

.u-m-s {
  margin: 12px; }

.u-m-m {
  margin: 16px; }

.u-m-l {
  margin: 20px; }

.u-m-xl {
  margin: 28px; }

.u-m-xxl {
  margin: 36px; }

.u-m-xxxl {
  margin: 48px; }

.u-mt-a {
  margin-top: auto; }

.u-mt-xxs {
  margin-top: 4px; }

.u-mt-xs {
  margin-top: 8px; }

.u-mt-s {
  margin-top: 12px; }

.u-mt-m {
  margin-top: 16px; }

.u-mt-l {
  margin-top: 20px; }

.u-mt-xl {
  margin-top: 28px; }

.u-mt-xxl {
  margin-top: 36px; }

.u-mt-xxxl {
  margin-top: 48px; }

.u-mb-a {
  margin-bottom: auto; }

.u-mb-xxs {
  margin-bottom: 4px; }

.u-mb-xs {
  margin-bottom: 8px; }

.u-mb-s {
  margin-bottom: 12px; }

.u-mb-m {
  margin-bottom: 16px; }

.u-mb-l {
  margin-bottom: 20px; }

.u-mb-xl {
  margin-bottom: 28px; }

.u-mb-xxl {
  margin-bottom: 36px; }

.u-mb-xxxl {
  margin-bottom: 48px; }

.u-ml-a {
  margin-left: auto; }

.u-ml-xxs {
  margin-left: 4px; }

.u-ml-xs {
  margin-left: 8px; }

.u-ml-s {
  margin-left: 12px; }

.u-ml-m {
  margin-left: 16px; }

.u-ml-l {
  margin-left: 20px; }

.u-ml-xl {
  margin-left: 28px; }

.u-ml-xxl {
  margin-left: 36px; }

.u-ml-xxxl {
  margin-left: 48px; }

.u-mr-a {
  margin-right: auto; }

.u-mr-xxs {
  margin-right: 4px; }

.u-mr-xs {
  margin-right: 8px; }

.u-mr-s {
  margin-right: 12px; }

.u-mr-m {
  margin-right: 16px; }

.u-mr-l {
  margin-right: 20px; }

.u-mr-xl {
  margin-right: 28px; }

.u-mr-xxl {
  margin-right: 36px; }

.u-mr-xxxl {
  margin-right: 48px; }

.u-p-a {
  padding: auto; }

.u-p-xxs {
  padding: 4px; }

.u-p-xs {
  padding: 8px; }

.u-p-s {
  padding: 12px; }

.u-p-m {
  padding: 16px; }

.u-p-l {
  padding: 20px; }

.u-p-xl {
  padding: 28px; }

.u-p-xxl {
  padding: 36px; }

.u-p-xxxl {
  padding: 48px; }

.u-pt-a {
  padding-top: auto; }

.u-pt-xxs {
  padding-top: 4px; }

.u-pt-xs {
  padding-top: 8px; }

.u-pt-s {
  padding-top: 12px; }

.u-pt-m {
  padding-top: 16px; }

.u-pt-l {
  padding-top: 20px; }

.u-pt-xl {
  padding-top: 28px; }

.u-pt-xxl {
  padding-top: 36px; }

.u-pt-xxxl {
  padding-top: 48px; }

.u-pb-a {
  padding-bottom: auto; }

.u-pb-xxs {
  padding-bottom: 4px; }

.u-pb-xs {
  padding-bottom: 8px; }

.u-pb-s {
  padding-bottom: 12px; }

.u-pb-m {
  padding-bottom: 16px; }

.u-pb-l {
  padding-bottom: 20px; }

.u-pb-xl {
  padding-bottom: 28px; }

.u-pb-xxl {
  padding-bottom: 36px; }

.u-pb-xxxl {
  padding-bottom: 48px; }

.u-pl-a {
  padding-left: auto; }

.u-pl-xxs {
  padding-left: 4px; }

.u-pl-xs {
  padding-left: 8px; }

.u-pl-s {
  padding-left: 12px; }

.u-pl-m {
  padding-left: 16px; }

.u-pl-l {
  padding-left: 20px; }

.u-pl-xl {
  padding-left: 28px; }

.u-pl-xxl {
  padding-left: 36px; }

.u-pl-xxxl {
  padding-left: 48px; }

.u-pr-a {
  padding-right: auto; }

.u-pr-xxs {
  padding-right: 4px; }

.u-pr-xs {
  padding-right: 8px; }

.u-pr-s {
  padding-right: 12px; }

.u-pr-m {
  padding-right: 16px; }

.u-pr-l {
  padding-right: 20px; }

.u-pr-xl {
  padding-right: 28px; }

.u-pr-xxl {
  padding-right: 36px; }

.u-pr-xxxl {
  padding-right: 48px; }

.u-txt-bourgeois {
  font-size: 12px;
  line-height: 16px; }

.u-txt-longprimer {
  font-size: 14px;
  line-height: 20px; }

.u-txt-pica {
  font-size: 16px;
  line-height: 20px; }

.u-txt-english {
  font-size: 18px;
  line-height: 24px; }

.u-txt-greatprimer {
  font-size: 22px;
  line-height: 28px; }

.u-txt-paragon {
  font-size: 27px;
  line-height: 36px; }

.u-txt-shadow {
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2); }

.u-txt-truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.u-txt-upper {
  text-transform: uppercase; }

.u-txt-lower {
  text-transform: lowercase; }

.u-txt-capital::first-letter {
  text-transform: uppercase; }

.u-txt-right {
  text-align: right; }

.u-txt-center {
  text-align: center; }

.u-txt-left {
  text-align: left; }

.u-txt-italic {
  font-style: italic; }

.u-txt-strong {
  font-weight: 500; }

.u-txt-default-font {
  font-family: sans-serif; }

.u-txt-underline {
  text-decoration: underline; }

.u-pos-r {
  position: relative; }

.u-pos-a {
  position: absolute; }

.u-pos-full {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.u-d-b {
  display: block; }

.u-d-ib {
  display: inline-block; }

.u-d-n {
  display: none; }

@media screen and (max-width: 768px) {
  .u-d-n\/m {
    display: none; } }

@media screen and (max-width: 480px) {
  .u-d-n\/s {
    display: none; } }

.u-v-h {
  visibility: hidden; }

.u-ovy-s {
  overflow-y: scroll; }

.u-ovy-h {
  overflow-y: hidden; }

.u-ovy-a {
  overflow-y: auto; }

.u-va-t {
  vertical-align: top; }

.u-va-m {
  vertical-align: middle; }

.u-va-tt {
  vertical-align: text-top; }

.u-bs-s {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15); }

.u-bs-m {
  box-shadow: 0 1px 9px 4px rgba(0, 0, 0, 0.15); }

.u-cursor-p {
  cursor: pointer; }

.u-height-full {
  height: 100%; }

.u-height-0 {
  height: 0; }

.u-minheight-full {
  min-height: 100%; }

.u-minheight-0 {
  min-height: 0; }

.u-maxheight-full {
  max-height: 100%; }

.u-width-full {
  width: 100%; }

.u-shadow-s {
  box-shadow: 0 1px 9px 4px rgba(0, 0, 0, 0.15); }

.u-outline-none {
  outline: none; }

.u-fake-label {
  line-height: 0;
  visibility: hidden;
  height: 0; }
